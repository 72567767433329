@for (worker of workers$ | async; track worker.guid) {
  <h4>{{ worker.name }}</h4>
  <div>
    <ion-item lines="none"
      color="primary"
      class="header">
      <div class="grid">
        <div>{{ 'report.date' | translate }}</div>
        <div>{{ 'report.site' | translate }}</div>
        <div>{{ 'report.start' | translate }}</div>
        <div>{{ 'report.end' | translate }}</div>
        <div>{{ 'report.lunch' | translate }}</div>
        <div>{{ 'report.workHours' | translate }}</div>
        <div>{{ 'report.overtime50Hours' | translate }}</div>
        <div>{{ 'report.overtime100Hours' | translate }}</div>
        <div>{{ 'report.invoice' | translate }}</div>
      </div>
    </ion-item>
    @for (date of worker.dates; track date.date) {
      <ion-item [color]="date.invoice === 0 ? 'danger' : 'secondary'"
        lines="none">
        <div class="grid">
          <div>{{ date.date | timestamp:'d.M.yyyy' }}</div>
          <div>
            @if (date.absence) {
              <ion-label>
                {{ 'timesheet.absence' | translate }}: {{ 'timesheet.absences.' + date.absence | translate }}
              </ion-label>
            } @else {
              <ion-button>
                <ion-label>{{ date.sites }}</ion-label>
              </ion-button>
            }
          </div>
          <div>
            @if (date.absence) {
              <ion-button>-</ion-button>
            } @else {
              <ion-button>
                {{ date.start | timestamp:'HH:mm' }}
              </ion-button>
            }
          </div>
          <div>
            @if (date.absence) {
              <ion-button>-</ion-button>
            } @else {
              <ion-button>
                {{ date.end | timestamp:'HH:mm' }}
              </ion-button>
            }
          </div>
          <div>
            <ion-button>
              {{ date.lunch }}min
            </ion-button>
          </div>
          <div>
            <ion-button>
              {{ date.workHours | duration }}
            </ion-button>
          </div>
          <div>
            <ion-button [color]="date.overtime50 <= 0 ? 'primary' : 'danger'">
              @if (date.overtime50 <= 0) {
                -
              } @else {
                {{ date.overtime50 | duration }}
              }
            </ion-button>
          </div>
          <div>
            <ion-button [color]="date.overtime100 <= 0 ? 'primary' : 'danger'">
              @if (date.overtime100 <= 0) {
                -
              } @else {
                {{ date.overtime100 | duration }}
              }
            </ion-button>
          </div>
          <div>
            @if (date.absence) {
              <ion-button>-</ion-button>
            } @else {
              <ion-button>
                {{ date.invoice | duration }}
              </ion-button>
            }
          </div>
          <div>
            <ion-label>
              {{ date.info$ | async }}
            </ion-label>
          </div>
        </div>
      </ion-item>
    }
    <ion-item lines="none"
      color="primary"
      class="footer">
      <div class="grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div>{{ worker.workHoursSum() | duration }}</div>
        <div>{{ worker.overtime50Sum() | duration }}</div>
        <div>{{ worker.overtime100Sum() | duration }}</div>
        <div>{{ worker.invoiceSum() | duration }}</div>
      </div>
    </ion-item>
  </div>
}