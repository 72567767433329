import { CommonModule } from '@angular/common';
import { Component, computed, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EnvironmentInjector, HostListener, inject, signal, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Timestamp } from '@angular/fire/firestore';
import { Dialog } from '@capacitor/dialog';
import { IonicSlides, IonSearchbar, ModalController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, firstValueFrom, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { PartnerHistoryItem, PartnerNote, PartnerNoteStatus } from 'src/app/models/partner-note.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { PartnerNotesService } from 'src/app/services/partner-notes.service';
import { PlusService } from 'src/app/services/plus.service';
import { UsersService } from 'src/app/services/users.service';
import { mobileWidth } from 'src/app/utility/constants';
import { Roles } from 'src/app/utility/role';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Swiper from 'swiper';
import { PartnerNoteDialogComponent } from '../notes/dialog/partner-note.dialog';
import { LiveFilterDialogComponent } from './dialog/filter-live.dialog';

interface Prospect extends PartnerNote {
  creator: SubItem;
}

const dragDataKey = 'text';

@Component({
  selector: 'app-partner-live-page',
  templateUrl: './live.page.html',
  styleUrls: ['./live.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
    TimestampPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PartnerLivePage {
  private injectorContext = inject(EnvironmentInjector);
  private modalCtrl = inject(ModalController);
  private plusService = inject(PlusService);
  private service = inject(PartnerNotesService);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  private filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private selectedUser = signal<SubItem>(null);
  private selectedPremium = signal<(SubItem & { partners: string[] })>(null);
  private dragItems = {};
  private slides: Swiper;

  private users$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => [Roles.superAdmin].includes(user.role) || this.usersService.isPremiumPartner(user) ?
      this.usersService.getList(null, { role: Roles.partner }).pipe(
        map((users) => {
          if (!users.some((it) => it.guid === user.guid)) {
            return [...users, user];
          }
          return users;
        }),
      ) : of([user])),
    shareReplay(1),
  );

  private items$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => user.role === Roles.superAdmin ?
      this.service.getList() :
      this.usersService.isPremiumPartner(user) ?
        this.service.getList({ users: [user.guid, ...user.partners] }) :
        this.service.getList({ user: user.guid })),
    switchMap((items) => combineLatest([
      of(items), this.filter$.asObservable(),
      toObservable(this.selectedUser, { injector: this.injectorContext }),
      toObservable(this.selectedPremium, { injector: this.injectorContext }), this.users$,
    ])),
    map(([items, filterValue, user, premium, users]) => items
      .filter((it) => it.target.toLowerCase().includes(filterValue.toLowerCase()) &&
        (user == null || it.inviter === user.guid) &&
        (premium == null || it.inviter === premium.guid || premium.partners.includes(it.inviter))
      ).map((it) => {
        const creator = users.find((u) => u.guid === it.inviter);
        return Object.assign({}, it, { creator: creator != null ? { guid: creator?.guid, name: creator.displayName } : null });
      })),
    shareReplay(1),
  );

  private partners$ = this.users$.pipe(
    map((users) => users.map((it) => ({ guid: it.guid, name: it.displayName }))),
    shareReplay(1),
  );

  private premiumPartners$ = this.users$.pipe(
    map((users) => users.filter((it) => it.rights?.premiumPartner).map((it) => ({ guid: it.guid, name: it.displayName, partners: it.partners }))),
    shareReplay(1),
  );

  @ViewChild('swiper')
  set swiper(swiperRef: ElementRef) {
    /**
     * This setTimeout waits for Ionic's async initialization to complete.
     * Otherwise, an outdated swiper reference will be used.
     */
    setTimeout(() => {
      this.slides = swiperRef?.nativeElement.swiper;
    }, 0);
  }

  swiperModules = [IonicSlides];

  prospects$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'prospect')),
    switchMap((items) => combineLatest([of(items), this.usersService.getMyPremiumPartner().pipe(
      switchMap((premium) => premium != null ? this.service.getList({ user: premium.guid, status: 'prospect' }).pipe(
        map((premiumItems) => premiumItems.map((it) => Object.assign({}, it, { creator: { guid: premium.guid, name: premium.displayName } }))),
      ) : of<Prospect[]>([]))
    ), this.filter$.asObservable(),
    ])),
    map(([items, premiums, filterValue]) => [...items, ...premiums.filter((it) => it.target.toLowerCase().includes(filterValue.toLowerCase()))]),
    shareReplay(1),
  );

  contacted$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'contacted')),
    map((items) => items.map((it) => {
      const contactType = it.history.find((itt) => itt.status === 'contacted')?.contactType;
      return Object.assign({}, it, { contactType });
    })),
    shareReplay(1),
  );

  notInterested$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'notInterested')),
    shareReplay(1),
  );

  didNotAnswer$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'didNotAnswer')),
    shareReplay(1),
  );

  booked$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'booked')),
    shareReplay(1),
  );

  meeting$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'meeting')),
    shareReplay(1),
  );

  offered$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'offered')),
    shareReplay(1),
  );

  contractSend$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'contractSend')),
    shareReplay(1),
  );

  done$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'done')),
    shareReplay(1),
  );

  archived$ = this.items$.pipe(
    map((items) => items.filter((it) => it.status === 'archived')),
    shareReplay(1),
  );

  roleS = computed(() => this.usersService.role());

  mobileView = signal<boolean>(false);

  constructor() {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView.set(window.innerWidth < mobileWidth);
  }

  slidePrev() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }

  onFilter(input: IonSearchbar) {
    const filterValue = input.value;
    this.filter$.next(filterValue);
  }

  onNewNote(status: string) {
    this.plusService.onNewPartnerNote(status);
  }

  moveTo(items: (PartnerNote & { creator: { guid: string; name: string } })[], status: string) {
    Dialog.confirm({
      message: this.translate.instant('general.move'),
      okButtonTitle: this.translate.instant('general.yes'),
      cancelButtonTitle: this.translate.instant('general.no'),
    }).then((dialog) => {
      if (dialog.value) {
        items.map(async (item) => this.service.updateOnly(item.guid, { status }));
      }
    });
  }

  onItem(item: (PartnerNote & { contactType?: string })) {
    this.modalCtrl.create({ component: PartnerNoteDialogComponent, componentProps: { item } }).then((m) => {
      m.present();
      m.onDidDismiss<PartnerNote>().then((data) => {
        if (data.data) {
          const updated = Object.assign({}, item, data.data);
          const user = this.usersService.currentUserS();
          if (user.role === Roles.partner && !this.usersService.isPremiumPartner(user) && updated.inviter !== user.guid) {
            updated.inviter = user.guid;
          }
          delete updated.contactType;
          this.service.update(updated);
        }
      });
    });
  }

  allowDrop(e: DragEvent) {
    e.preventDefault();
  }

  drag(event: DragEvent, item: PartnerNote) {
    const guid = event.target['id'];
    event.dataTransfer.setData(dragDataKey, guid);
    this.dragItems[guid] = { item };
  }

  drop(e: DragEvent, status: PartnerNoteStatus) {
    e.preventDefault();
    const data = e.dataTransfer.getData(dragDataKey);
    const dragItem: { item: PartnerNote } = this.dragItems[data];
    const history: PartnerHistoryItem = {
      status: status,
      contact: '',
      interested: '',
      future: '',
      date: Timestamp.now(),
      user: { guid: this.usersService.currentUserS().guid, name: this.usersService.currentUserS().displayName },
    };
    dragItem.item.history.push(history);
    this.service.updateOnly(dragItem.item.guid, { status }).then(() => this.dragItems = {});
    this.service.updateOnly(dragItem.item.guid, { history: dragItem.item.history }).then(() => this.dragItems = {});
    const user = this.usersService.currentUserS();
    if (user.role === Roles.partner && !this.usersService.isPremiumPartner(user) && dragItem.item.inviter !== user.guid) {
      this.service.updateOnly(dragItem.item.guid, { inviter: user.guid });
    }
  }

  async onFilters() {
    const user = this.usersService.currentUserS();
    const users = await firstValueFrom(this.partners$);
    if (users.find((it) => it.guid === null) == null) {
      users.push({ guid: null, name: this.translate.instant('general.clear') });
    }
    let premiumPartners = [];
    if (user.role === Roles.superAdmin) {
      premiumPartners = await firstValueFrom(this.premiumPartners$);
      if (premiumPartners.find((it) => it.guid === null) == null) {
        premiumPartners.push({ guid: null, name: this.translate.instant('general.clear'), partners: [] });
      }
    }
    this.modalCtrl.create({
      component: LiveFilterDialogComponent,
      componentProps: { users, premiumPartners, selected: this.selectedUser(), selectedPremium: this.selectedPremium() },
    }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.user) {
            if (data.data.user.guid != null) {
              this.selectedUser.set(data.data.user);
            } else {
              this.selectedUser.set(null);
            }
          }
          if (data.data.premiumPartner) {
            if (data.data.premiumPartner.guid != null) {
              this.selectedPremium.set(data.data.premiumPartner);
            } else {
              this.selectedPremium.set(null);
            }
          }
        }
      });
    });
  }

}
