<app-main-page>
  <div desktopTitle>
    {{ 'nav.projects' | translate }}
  </div>
  <div class="ion-margin-bottom">
    <app-segment-list [segmentValue]="navSegmentValue()"
      [segmentButtons]="navSegmentButtons"
      (segmentChanged)="navSegmentChanged($event)">
    </app-segment-list>
  </div>
  @switch (navSegmentValue()) {
    @case ('overview') {
      <h1>{{ 'nav.overview' | translate }}</h1>
      <p>{{ 'overviews.projects' | translate }}</p>
      <ion-label>{{ 'nav.projects' | translate }}</ion-label>
      <app-snap-links [items]="snapLinks$ | async"></app-snap-links>
    } @case ('map') {
      <div class="map-container-mobile">
        <app-sites-map [sites]="sitesWithProjects$ | async"></app-sites-map>
      </div>
    } @case ('calendar') {
      <div class="sticky">
        <app-week-switch (dateChanged)="onDateChange($event)"></app-week-switch>
        <ion-searchbar mode="ios"
          [placeholder]="'general.search' | translate"
          type="text"
          debounce="500"
          (ionInput)="onFilter($event)"></ion-searchbar>
      </div>
      @if (loading()) {
        <app-loading></app-loading>
      }
      @for (site of sitesWithProjects$ | async; track site.guid; let index = $index) {
        <app-calendar [skipHeader]="index !== 0"
          [selectedDay]="selectedDay()"
          [site]="site"
          [showSite]="true"></app-calendar>
      }
    } @case ('readiness') {
      <app-dashboard></app-dashboard>
    } @case ('invoice') {}
  }
</app-main-page>