import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { WeekCalendarComponent } from '../components/calendar/calendar.component';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { SelectUserButtonComponent, SelectedUser } from '../components/select-user/select-user.component';
import { SchedulesService } from '../services/schedules.service';
import { SitesService } from '../services/sites.service';
import { UsersService } from '../services/users.service';
import { getCalendarItem } from '../utility/item';
import { Roles } from '../utility/role';

@Component({
  selector: 'app-user-week-calendar-page',
  templateUrl: './user-week-calendar.page.html',
  styleUrls: ['./user-week-calendar.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
    SelectUserButtonComponent,
    WeekCalendarComponent,
  ],
})
export class UserWeekCalendarPageComponent {
  private activatedRoute = inject(ActivatedRoute);
  private schedulesService = inject(SchedulesService);
  private sitesService = inject(SitesService);
  private usersService = inject(UsersService);

  mobileView = signal(false);

  selectedUser = signal<SelectedUser>(null);
  subId = computed(() => this.selectedUser()?.guid);

  items$ = toObservable(this.selectedUser).pipe(
    filter((user) => user != null),
    switchMap((user) => combineLatest([this.schedulesService.getList({ user: user.guid }), this.sitesService.getActiveList()])),
    map(([items, sites]) => items?.map((it) => {
      const time = it.times.find((itt) => itt.user === this.selectedUser().guid);
      if (time.status === 'draft') {
        return null;
      }
      return getCalendarItem(it, this.selectedUser().guid, sites);
    })),
    map((items) => items.filter((it) => it != null)),
    shareReplay(1),
  );

  users$ = combineLatest([this.usersService.getList(undefined, { role: Roles.worker }), this.activatedRoute.paramMap]).pipe(
    tap(([users, params]) => {
      const user = this.usersService.currentUserS();
      if ([Roles.worker].includes(user.role)) {
        this.selectedUser.set({ guid: user.guid, name: user.displayName, role: user.role, displayName: user.displayName });
      } else if (params.get('id')) {
        const idUser = users.find((it) => it.guid === params.get('id'));
        if (idUser != null) {
          this.selectedUser.set({ guid: idUser.guid, name: idUser.displayName, role: idUser.role, displayName: idUser.displayName });
        }
      }
    }),
    map(([users]) => users.sort(fieldSorter(['displayName']))),
    shareReplay(1),
  );

  role = computed(() => this.usersService.role());

  selectUser(selectedUser: SelectedUser) {
    this.selectedUser.set(selectedUser);
  }
}
