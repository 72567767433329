import { CommonModule } from '@angular/common';
import { Component, computed, HostListener, inject, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ModalController } from '@ionic/angular/standalone';
import { fieldSorter, TimestampPipe } from '@scandium-oy/ngx-scandium';
import { addYears, startOfDay, startOfToday } from 'date-fns';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { NoResultsComponent } from 'src/app/components/no-results/no-results.component';
import { SelectSiteDialog } from 'src/app/components/select-site/select-site.dialog';
import { SubItem } from 'src/app/models/sub-item.model';
import { Timesheet } from 'src/app/models/timesheet.model';
import { DurationPipe } from 'src/app/pipes/duration/duration.pipe';
import { acceptTimesheet } from 'src/app/report/helper';
import { ContractorsService } from 'src/app/services/contractors.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { UsersService } from 'src/app/services/users.service';
import { mobileWidth } from 'src/app/utility/constants';
import { Roles } from 'src/app/utility/role';
import { addTimesheet, getDate, updateSum } from 'src/app/utility/timesheet';
import { EditTimesheetDialogComponent } from './dialog/edit-timesheet.dialog';
import { RequestSummaryDialogComponent } from './dialog/request-summary.dialog';

export interface TsWorker {
  guid: string;
  name: string;
  dates: TsWorkerDate[];
  workHoursSum: WritableSignal<number>;
  overtime50Sum: WritableSignal<number>;
  overtime100Sum: WritableSignal<number>;
  kmSum: WritableSignal<number>;
  dayAllowanceSum: WritableSignal<number>;
  paycheckSum: WritableSignal<number>;
  invoiceSum: WritableSignal<number>;
}

export interface TsWorkerDate {
  date: Date;
  timesheets: Timesheet[];
  sites: string;
  start: Date;
  end: Date;
  lunch: number;
  workHours: number;
  overtime50: number;
  overtime100: number;
  kmPay?: number;
  dayAllowance?: number;
  paycheck: number;
  invoice: number;
  absence: string;
  requested: boolean;
  info$?: Observable<string>;
}

@Component({
  selector: 'app-process-timesheets',
  templateUrl: './process-timesheets.component.html',
  styleUrls: ['./process-timesheets.component.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    TimestampPipe,
    DurationPipe,
    NoResultsComponent,
  ],
})
export class ProcessTimesheetsComponent {
  private contractorsService = inject(ContractorsService);
  private modalCtrl = inject(ModalController);
  private sitesService = inject(SitesService);
  private timesheetsService = inject(TimesheetsService);
  private usersService = inject(UsersService);

  private sites$ = combineLatest([this.sitesService.getAdminList(), this.usersService.getCurrentUser()]).pipe(
    filter(([sites, user]) => sites != null && user != null),
    map(([sites, user]) => {
      const ownSites = sites.filter((site) => [Roles.admin].includes(user.role) || site.supervisor?.some((u) => u.guid === user.guid) || this.usersService.isSitelead(site));
      return ownSites;
    }),
  );

  private users$ = this.usersService.getList().pipe(shareReplay(1));

  timesheetFilters = computed(() => {
    const filters = this.timesheetsService.timesheetFilters();
    if (filters.startDate == null) {
      filters.startDate = addYears(startOfToday(), -5);
    }
    if (filters.endDate == null) {
      filters.endDate = startOfToday();
    }
    return filters;
  });

  timesheets = signal<Timesheet[]>([]);
  mobileView = signal(false);

  workers$ = combineLatest([
    toObservable(this.timesheetFilters), this.usersService.getCurrentUser(), this.sitesService.isSiteLead$,
  ]).pipe(
    filter(([filters, user]) => !!filters && user != null),
    switchMap(([filters, user, isSitelead]) => combineLatest(
      [
        this.timesheetsService.getList({
          startDate: filters.startDate,
          endDate: filters.endDate,
          checked: false,
          contractor: user.contractors[0],
          site: filters?.site,
          sites: filters?.site == null ? isSitelead ? this.sitesService.getSiteleadSites().map((s) => s.guid) : undefined : undefined,
          project: filters?.project,
        }),
        this.users$,
        this.sites$,
      ]),
    ),
    map(([timesheets, users, sites]) => {
      timesheets = timesheets.filter((it) => this.timesheetFilters().users == null || this.timesheetFilters().users.includes(it.user));
      timesheets = timesheets.filter((ts) => sites.map((s) => s.guid).includes(ts.site) || ts.absence != null);
      const workers = Array.from<TsWorker>([]);
      timesheets.map((timesheet) => {
        const user = users.find((u) => u.guid === timesheet.user);
        const old = workers.find((w) => w.guid === user.guid);
        timesheet.username = timesheet.username ?? user?.displayName;
        if (old) {
          let oldDate = old.dates.find((it) => it.date.toLocaleDateString('fi') === startOfDay(timesheet.dateIn).toLocaleDateString('fi'));
          if (oldDate) {
            oldDate = addTimesheet(oldDate, timesheet);
            updateSum(timesheet, old, oldDate.lunch);
          } else {
            const date = getDate(this.contractorsService.contractorS(), timesheet);
            updateSum(timesheet, old, date.lunch);
            old.dates.push(date);
          }
        } else {
          const date = getDate(this.contractorsService.contractorS(), timesheet);
          const item = {
            guid: user.guid,
            name: user.displayName,
            dates: [date],
            workHoursSum: signal(0),
            overtime50Sum: signal(0),
            overtime100Sum: signal(0),
            kmSum: signal(0),
            dayAllowanceSum: signal(0),
            paycheckSum: signal(0),
            invoiceSum: signal(0),
          };
          updateSum(timesheet, item, date.lunch);
          workers.push(item);
        }
      });
      this.timesheets.set(timesheets);
      return workers.sort(fieldSorter(['name']));
    }),
    shareReplay(1),
  );

  constructor() {
    this.onResize();
  }

  private openSummary(timesheets: Timesheet[]) {
    this.modalCtrl.create({ component: RequestSummaryDialogComponent, componentProps: { timesheets }, cssClass: ['modal-90'] }).then((m) => {
      m.present();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileView.set(window.innerWidth < mobileWidth);
  }

  onEdit(item: TsWorkerDate, field: string) {
    this.modalCtrl.create({ component: EditTimesheetDialogComponent, componentProps: { field, item } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          this.timesheetsService.updateOnly(data.data.guid, data.data);
        }
      });
    });
  }

  askCheck() {
    const sites: SubItem[] = [];
    this.timesheets().forEach((it) => {
      if (it.absence == null && !sites.some((itt) => itt.guid === it.site)) {
        sites.push({ guid: it.site, name: it.siteName });
      }
    });
    if (sites.length > 1) {
      this.modalCtrl.create({ component: SelectSiteDialog, componentProps: { sites } }).then((m) => {
        m.present();
        m.onDidDismiss().then((data) => {
          if (data.data) {
            const timesheets = this.timesheets().filter((it) => it.site === data.data.guid && !['requested', 'checked', 'reported'].includes(it.status));
            this.openSummary(timesheets);
          }
        });
      });
    } else {
      const timesheets = this.timesheets().filter((it) => it.site === sites[0].guid && !['requested', 'checked', 'reported'].includes(it.status));
      this.openSummary(timesheets);
    }
  }

  check() {
    const timesheets = this.timesheets().filter((it) => !['reported'].includes(it.status));
    timesheets.map((ts) => {
      ts = acceptTimesheet(ts, this.usersService.role());
      this.timesheetsService.update(ts);
    });
  }
}
