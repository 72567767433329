import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { NoResultsComponent } from 'src/app/components/no-results/no-results.component';
import { Timesheet } from 'src/app/models/timesheet.model';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { CheckTimesheetsComponent } from '../check-timesheets.component';

@Component({
  selector: 'app-request-summary-dialog',
  templateUrl: './request-summary.dialog.html',
  styleUrls: ['./request-summary.dialog.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainDialogComponent,
    CheckTimesheetsComponent,
    NoResultsComponent,
  ],
})
export class RequestSummaryDialogComponent {
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);
  private timesheetsService = inject(TimesheetsService);
  private translate = inject(TranslateService);

  timesheetsS = signal(this.navParams.get<Timesheet[]>('timesheets'));

  private sendTimesheets() {
    Dialog.prompt({
      message: this.translate.instant('timesheet.emailToSend'),
    }).then((confirmValue) => {
      if (confirmValue?.value?.length > 0) {
        const email = confirmValue.value;
        const timesheets = this.timesheetsS().map((it) => it.guid);
        if (timesheets.length > 0) {
          this.timesheetsService.sendTimesheets(timesheets, email).pipe(take(1)).subscribe(async () => this._modal.dismiss());
        } else {
          this._modal.dismiss();
        }
      }
    });
  }

  check() {
    this.sendTimesheets();
  }

  dismiss() {
    this._modal.dismiss();
  }
}
