import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { LoadingComponent } from '@scandium-oy/ngx-scandium';
import { endOfTomorrow, startOfYear } from 'date-fns';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { Site } from '../models/site.model';
import { HoursPipe } from '../pipes/duration/hours.pipe';
import { ContractorsService } from '../services/contractors.service';
import { SiteProjectsService } from '../services/site-projects.service';
import { SitesService } from '../services/sites.service';
import { TimesheetsService } from '../services/timesheets.service';
import { UsersService } from '../services/users.service';
import { defaultProjectName } from '../utility/kanban';
import { fieldSorter } from '../utility/sort';
import { capitalizeFirstLetter } from '../utility/string';
import { getDurations } from '../utility/timesheet';

// eslint-disable-next-line @typescript-eslint/no-type-alias
type Project = (Site & { projectName: string; hours$: Observable<number>; costs$: Observable<number> });

@Component({
  selector: 'app-profit',
  templateUrl: './profit.component.html',
  styleUrls: ['./profit.component.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    LoadingComponent,
    HoursPipe,
  ],
})
export class ProfitComponent {
  private contractorsService = inject(ContractorsService);
  private siteProjectsService = inject(SiteProjectsService);

  private sites$ = this.sitesService.getActiveList().pipe(
    filter((sites) => sites.length > 0),
  );

  private projects$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.siteProjectsService.getList({ contractor: contractor.guid })),
    shareReplay(1),
  );

  readonly hourlyRate = 27;

  workers$ = this.usersService.getList().pipe(
    shareReplay(1),
  );

  contracts$ = combineLatest([this.sites$, this.projects$, this.workers$]).pipe(
    map(([sites, projects, workers]) => projects.map((project) => {
      const site = sites.find((it) => it.guid === project.site);
      site.project = capitalizeFirstLetter(site.project);
      const timesheets$ = this.timesheets(site, project.guid);
      const hours$ = timesheets$.pipe(
        map((timesheets) => getDurations(timesheets)),
        shareReplay(1),
      );
      const costs$ = timesheets$.pipe(
        map((sheets) => {
          let ret = 0;
          sheets.map((sheet) => {
            const user = workers.find((u) => u.guid === sheet.user);
            const duration = getDurations([sheet]);
            if (duration > 0) {
              if (user != null) {
                if (user.salaryOrInvoice === 'salary') {
                  ret += (1.35 * user.hourlyRate) * duration;
                } else {
                  ret += user.hourlyRate * duration;
                }
              } else {
                // console.warn('Missing user ', sheet.user);
                ret += duration * 18;
              }
            }
          });
          return ret;
        }),
      );
      site.clientName = site.client ? capitalizeFirstLetter(site.client) : '';
      return Object.assign({}, site, { projectName: project.name, hours$, costs$ });
    }).sort(fieldSorter(['clientName', 'name']))),
    tap((items) => this.hourly.set(items.filter((it) => it.projectName === defaultProjectName))),
    map((items) => items.filter((it) => it.projectName !== defaultProjectName)),
    shareReplay(1),
  );

  hourly = signal<Project[]>([]);

  constructor(
    private sitesService: SitesService,
    private timesheetService: TimesheetsService,
    private usersService: UsersService,
  ) { }

  private timesheets(site: Site, project: string) {
    const startDate = startOfYear(2020);
    const endDate = endOfTomorrow();
    return this.timesheetService.getList({ site: site.guid, startDate, endDate, project }).pipe(
      shareReplay(1),
    );
  }
}
