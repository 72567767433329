<app-main-page>
  <div desktopTitle>
    {{ 'login.title' | translate }}
  </div>
  @if (!hasPasscode()) {
    <h1>{{ 'signature.title' | translate }}</h1>
    <p>{{ 'signature.text' | translate }}</p>
    <ion-item lines="none">
      <ion-input [(ngModel)]="passcode"
        placeholder="A B C 0 0 0"
        [maskito]="passcodeMask"
        [maskitoElement]="maskPredicate"
        aria-label=""></ion-input>
    </ion-item>
    @if (error()) {
      <p>{{ 'signature.error' | translate }}</p>
    } @else if (expired()) {
      <p>{{ 'signature.expired' | translate }}</p>
    } @else if (alreadySigned()) {
      <p>{{ 'signature.alreadySigned' | translate }}</p>
    }
    <div class="ion-margin-top">
      <ion-button color="light"
        [disabled]="passcode()?.length < 6"
        (click)="enterPasscode()">
        <app-loading-button [loading]="loading()">
          {{ 'login.login' | translate }}
        </app-loading-button>
      </ion-button>
    </div>
  } @else {
    @if (signed()) {
      <h1>{{ 'general.done' | translate }}</h1>
      <p>{{ 'signature.done' | translate }}</p>
      <h4>{{ 'signature.email' | translate }}</h4>
      <div>{{ signed().email }}</div>
      <h4>{{ 'signature.ipAddress' | translate }}</h4>
      <div>{{ signed().ipAddress }}</div>
      <div class="ion-margin-top">
        <ion-button color="light"
          (click)="download()">
          <app-loading-button [loading]="loading()">
            {{ 'signature.download' | translate }}
          </app-loading-button>
        </ion-button>
      </div>
    } @else {
      <h1>{{ 'signature.checkDocument' | translate }}</h1>
      @if (type() === 'offer') {
        <ngx-extended-pdf-viewer [src]="url()"
          [height]="'600px'"></ngx-extended-pdf-viewer>
        <p>{{ 'signature.checkDocumentText' | translate }}</p>
        <div class="ion-margin-top">
          <ion-button color="light"
            (click)="sign()">
            <app-loading-button [loading]="loading()">
              {{ 'signature.sign' | translate }}
            </app-loading-button>
          </ion-button>
        </div>
      } @else if (type() === 'timesheet') {
        <app-check-timesheets [timesheets]="timesheets()"></app-check-timesheets>
        <div class="ion-margin-top">
          <ion-button color="light"
            (click)="sign()">
            <app-loading-button [loading]="loading()">
              {{ 'timesheet.accept' | translate }}
            </app-loading-button>
          </ion-button>
        </div>
      }
    }
  }
</app-main-page>