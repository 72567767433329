<app-main-page>
  <div desktopTitle>
    {{ 'nav.reports' | translate }}
  </div>
  <div class="ion-margin-bottom">
    <app-segment-list [segmentValue]="navSegmentValue()"
      [segmentButtons]="navSegmentButtons()"
      (segmentChanged)="navSegmentChanged($event)">
    </app-segment-list>
  </div>
  @switch (navSegmentValue()) {
    @case ('efficiency') {
      <app-efficiency></app-efficiency>
    } @case ('report') {
      <app-report-site></app-report-site>
    } @case ('profit') {
      <app-profit></app-profit>
    }
  }
</app-main-page>