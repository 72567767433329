import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { ShiftComponent } from './shift.component';

@Component({
  selector: 'app-shift-page',
  templateUrl: './shift.page.html',
  styleUrls: ['./shift.page.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainPageComponent,
    ShiftComponent,
  ],
})
export class ShiftPage {

}
