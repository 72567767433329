import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { take } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { LoadingButtonComponent } from '../components/loading-button/loading-button.component';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { Timesheet } from '../models/timesheet.model';
import { SignatureService } from '../services/signature.service';
import { CheckTimesheetsComponent } from '../timesheets/process/component/check-timesheets.component';

@Component({
  selector: 'app-signature-page',
  templateUrl: './signature.page.html',
  styleUrls: ['./signature.page.scss'],
  providers: [SignatureService],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
    FormsModule,
    LoadingButtonComponent,
    MaskitoDirective,
    NgxExtendedPdfViewerModule,
    CheckTimesheetsComponent,
  ],
})
export class SignaturePage {
  private route = inject(ActivatedRoute);
  private signatureService = inject(SignatureService);
  private translate = inject(TranslateService);

  private item = '';
  url = signal<string>(null);
  timesheets = signal<Timesheet[]>([]);

  readonly passcodeMask: MaskitoOptions = {
    mask: [/\w/, ' ', /\w/, ' ', /\w/, ' ', /\w/, ' ', /\w/, ' ', /\w/],
  };

  readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

  hasPasscode = signal(false);
  passcode = signal<string>(null);
  loading = signal(false);
  error = signal(false);
  expired = signal(false);
  alreadySigned = signal(false);
  type = signal<'offer' | 'timesheet'>(null);

  signed = signal<{ ipAddress: string; email: string }>(null);

  enterPasscode() {
    this.loading.set(true);
    this.error.set(false);
    const item = this.route.snapshot.paramMap.get('id');
    this.signatureService.passcode(item, this.passcode().replace(/\s/g, '')).pipe(
      take(1),
    ).subscribe({
      next: (ret) => {
        if (ret.status === 'invalid') {
          this.error.set(true);
        } else if (ret.status === 'expired') {
          this.expired.set(true);
        } else if (ret.status === 'signed') {
          this.alreadySigned.set(true);
        } else {
          this.type.set(ret.type);
          this.hasPasscode.set(true);
          if (ret.type === 'offer') {
            this.item = ret.item;
            this.url.set(ret.url);
          } else if (ret.type === 'timesheet') {
            this.item = ret.item;
            this.timesheets.set(ret.items ?? []);
          }
        }
      },
      complete: () => this.loading.set(false),
      error: () => this.loading.set(false),
    });
  }

  sign() {
    Dialog.confirm({
      message: this.translate.instant('signature.confirmSignature'),
      okButtonTitle: this.translate.instant('general.yes'),
      cancelButtonTitle: this.translate.instant('general.no'),
    }).then((dialog) => {
      if (dialog.value) {
        this.loading.set(true);
        const guid = this.route.snapshot.paramMap.get('id');
        this.signatureService.sign(guid, this.item).pipe(
          take(1),
        ).subscribe({
          next: (ret) => {
            this.signed.set({ ipAddress: ret.ipAddress, email: ret.email });
          },
          complete: () => this.loading.set(false),
          error: () => this.loading.set(false),
        });
      }
    });
  }

  download() {
    Browser.open({ url: this.url() });
  }
}
