import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isRoleGuard } from './guards/is-role.guard';
import { isSiteleadGuard } from './guards/sitelead.guard';
import { ImportProspectsPage } from './imports/prospects/import-prospects.page';
import { InvitesPage } from './invites/invites.page';
import { InvoicingPage } from './invoicing/invoicing.page';
import { OffersPage } from './offers/offers.page';
import { PartnerLivePage } from './partner/live/live.page';
import { PartnerNotesPage } from './partner/notes/partner-notes.page';
import { PrivacyPage } from './privacy/privacy.page';
import { ProjectsPage } from './projects/projects.page';
import { ReportsPage } from './report/reports.page';
import { SignaturePage } from './signature/signature.page';
import { StripeCancelPage } from './stripe/cancel/cancel.page';
import { StripeOrderPage } from './stripe/order/order.page';
import { StripeSuccessPage } from './stripe/success/success.page';
import { ProcessTimesheetsPage } from './timesheets/process/process-timesheets.page';
import { TimesheetsPage } from './timesheets/timesheets.page';
import { UserWeekCalendarPageComponent } from './user-week-calendar/user-week-calendar.page';
import { Roles } from './utility/role';
import { ShiftPage } from './work-force/shift.page';
import { TicketPageComponent } from './worker/ticket/ticket.page';

export const routes: Routes = [
  {
    path: 'calendar',
    loadChildren: async () =>
      import('./calendar/calendar.routing.module'),
  },
  {
    path: 'clients',
    loadChildren: async () =>
      import('./clients/clients.routing.module'),
  },
  {
    path: 'contractors',
    loadChildren: async () =>
      import('./contractors/contractors.routing.module'),
  },
  {
    path: 'dashboard',
    loadChildren: async () =>
      import('./dashboard/dashboard.routing.module'),
  },
  {
    path: 'feedback',
    loadChildren: async () =>
      import('./feedback/feedback.routing.module'),
  },
  {
    path: 'import/prospects',
    component: ImportProspectsPage,
    canActivate: [isRoleGuard([Roles.superAdmin])],
  },
  {
    path: 'info',
    loadChildren: async () =>
      import('./info/info.routing.module'),
  },
  {
    path: 'partner/invites',
    component: InvitesPage,
  },
  {
    path: 'partner/live',
    component: PartnerLivePage,
    canActivate: [isRoleGuard([Roles.superAdmin, Roles.partner, Roles.premiumPartner])],
  },
  {
    path: 'partner/notes',
    component: PartnerNotesPage,
    canActivate: [isRoleGuard([Roles.superAdmin, Roles.partner, Roles.premiumPartner])],
  },
  {
    path: 'partner/offers',
    component: OffersPage,
    canActivate: [isRoleGuard([Roles.superAdmin, Roles.partner, Roles.premiumPartner])],
  },
  {
    path: 'privacy',
    component: PrivacyPage,
  },
  {
    path: 'invoice',
    loadChildren: async () =>
      import('./invoice/invoice.routing.module'),
  },
  {
    path: 'kanban-dashboard',
    loadChildren: async () =>
      import('./kanban/dashboard/kanban-dashboard.routing.module'),
  },
  {
    path: 'home',
    loadChildren: async () =>
      import('./home/home.routing.module'),
  },
  {
    path: 'instructions',
    loadChildren: async () =>
      import('./instructions/instructions.routing.module'),
  },
  {
    path: 'invoicing',
    component: InvoicingPage,
    canActivate: [isRoleGuard([Roles.superAdmin])],
  },
  {
    path: 'lastplanner',
    loadChildren: async () =>
      import('./lastplanner/lastplanner.routing.module'),
  },
  {
    path: 'littera',
    loadChildren: async () =>
      import('./littera/littera.routing.module'),
  },
  {
    path: 'login',
    loadChildren: async () =>
      import('./login/login.routing.module'),
  },
  {
    path: 'logs',
    loadChildren: async () =>
      import('./logs/logs-page.routing.module'),
  },
  {
    path: 'materials',
    loadChildren: async () =>
      import('./materials/materials.routing.module'),
  },
  {
    path: 'merge-users',
    loadChildren: async () =>
      import('./merge-users/merge-users.routing.module'),
  },
  {
    path: 'news',
    loadChildren: async () =>
      import('./news/news.routing.module'),
  },
  {
    path: 'options',
    loadChildren: async () =>
      import('./options/options.routing.module'),
  },
  {
    path: 'paycheck',
    loadChildren: async () =>
      import('./paycheck/paycheck.routing.module'),
  },
  {
    path: 'profile',
    loadChildren: async () =>
      import('./profile/profile.routing.module'),
  },
  {
    path: 'projects',
    component: ProjectsPage,
    canActivate: [isRoleGuard([Roles.admin, Roles.supervisor])],
  },
  {
    path: 'register',
    loadChildren: async () =>
      import('./register/register.routing.module'),
  },
  {
    path: 'reports',
    component: ReportsPage,
    canActivate: [isRoleGuard([Roles.admin, Roles.supervisor])],
  },
  {
    path: 'report/site',
    loadChildren: async () =>
      import('./report/site/report-site.routing.module'),
  },
  {
    path: 'report/user',
    loadChildren: async () =>
      import('./report/user/report-user.routing.module'),
  },
  {
    path: 'schedule',
    loadChildren: async () =>
      import('./schedule/schedule.routing.module'),
  },
  {
    path: 'sign-up',
    loadChildren: async () =>
      import('./on-boarding/on-boarding.routing.module'),
  },
  {
    path: 'signature/:id',
    component: SignaturePage,
  },
  {
    path: 'sites',
    loadChildren: async () =>
      import('./sites/sites.routing.module'),
  },
  {
    path: 'stripe/order',
    component: StripeOrderPage,
  },
  {
    path: 'stripe/success',
    component: StripeSuccessPage,
  },
  {
    path: 'stripe/cancel',
    component: StripeCancelPage,
  },
  {
    path: 'ticket',
    component: TicketPageComponent,
  },
  {
    path: 'process-timesheets',
    component: ProcessTimesheetsPage,
    canActivate: [isSiteleadGuard()],
  },
  {
    path: 'timesheets',
    component: TimesheetsPage,
    canActivate: [isSiteleadGuard()],
  },
  {
    path: 'users',
    loadChildren: async () =>
      import('./users/users.routing.module'),
  },
  {
    path: 'user-week-calendar',
    component: UserWeekCalendarPageComponent,
  },
  {
    path: 'user-week-calendar/:id',
    component: UserWeekCalendarPageComponent,
  },
  {
    path: 'week-user',
    loadChildren: async () => import('./kanban/user-week-view/user-week-view.routing-module'),
  },
  {
    path: 'waiting',
    loadChildren: async () =>
      import('./waiting/waiting.routing.module'),
  },
  {
    path: 'worker',
    loadChildren: async () =>
      import('./worker/worker.routing.module'),
  },
  {
    path: 'working-calendar',
    loadChildren: async () =>
      import('./working-calendar/working-calendar.routing.module'),
  },
  {
    path: 'worklist',
    component: ShiftPage,
    canActivate: [isRoleGuard([Roles.admin, Roles.supervisor])],
  },
  {
    path: '',
    redirectTo: environment.home,
    pathMatch: 'full',
  },
];
