@if (workers$ | async; as workers) {
  <h2>{{ 'profit.cleanings' | translate }}</h2>
  @if (contracts$ | async; as contracts) {
    @if (contracts.length > 0) {
      <div class="grid">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
      </div>
    }
    @for (site of contracts; track site.guid) {
      <ion-item lines="none">
        <div class="grid">
          <div class="client-name">
            <ion-label>
              {{ site.clientName$ | async }}
            </ion-label>
          </div>
          <div class="name">
            <ion-label>
              {{ site.project }}
            </ion-label>
          </div>
          <div class="name">
            <ion-label>
              {{ site.projectName }}
            </ion-label>
          </div>
          <div>
            @if (site.additionalInfo?.form?.totalSum) {
              {{ site.additionalInfo?.form?.totalSum | currency:'EUR' }}
            }
          </div>
          @if (site.hours$ | async; as hours) {
            <div>{{ hours | hours }}</div>
            @if (site.costs$ | async; as costs) {
              <div>{{ costs | currency:'EUR' }}</div>
              <div>
                @if (site.additionalInfo?.form?.totalSum) {
                  {{ site.additionalInfo?.form?.totalSum - costs | currency:'EUR' }}
                }
              </div>
            }
          }
        </div>
      </ion-item>
    } @empty {
      <div class="ion-margin-top">
        {{ 'dashboard.noItems' | translate }}
      </div>
    }
  } @else {
    <app-loading></app-loading>
  }
  <h2>{{ 'profit.maintance' | translate }}</h2>
  @if (hourly()) {
    @if (hourly().length > 0) {
      <div class="grid">
        <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
      </div>
    }
    @for (site of hourly(); track site.guid) {
      <ion-item lines="none">
        <div class="grid">
          <div class="client-name">
            <ion-label>
              {{ site.clientName$ | async }}
            </ion-label>
          </div>
          <div class="name">
            <ion-label>
              {{ site.project }}
            </ion-label>
          </div>
          <div class="name">
            <ion-label>
              {{ site.projectName }}
            </ion-label>
          </div>
          @if (site.hours$ | async; as hours) {
            <div>{{ (hours * hourlyRate)  | currency:'EUR' }}</div>
            <div>{{ hours | hours }}</div>
            @if (site.costs$ | async; as costs) {
              <div>{{ costs | currency:'EUR' }}</div>
              <div>
                {{ (hours * hourlyRate - costs) | currency:'EUR' }}
              </div>
            }
          }
        </div>
      </ion-item>
    } @empty {
      <div class="ion-margin-top">
        {{ 'dashboard.noItems' | translate }}
      </div>
    }
  } @else {
    <app-loading></app-loading>
  }
}
<ng-template #headerTemplate>
  <div>{{ 'profit.client' | translate }}</div>
  <div>{{ 'profit.site' | translate }}</div>
  <div>{{ 'timesheet.project' | translate }}</div>
  <div>{{ 'profit.sum' | translate }}</div>
  <div>{{ 'profit.hours' | translate }}</div>
  <div>{{ 'profit.costs' | translate }}</div>
  <div>{{ 'profit.result' | translate }}</div>
</ng-template>