<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (selectedTimesheet()) {
      {{ selectedTimesheet().date }} {{ selectedTimesheet().siteName }}
    } @else {
      {{ 'timesheet.title' | translate }}
    }
  </div>
  @if (selectedTimesheet() == null) {
    <h4>{{ 'timesheet.select' | translate }}</h4>
    @for(ts of itemS()?.timesheets; track ts.guid) {
      <ion-item lines="none"
        button="true"
        (click)="selectTimesheet(ts)">
        {{ ts.siteName }}
        <span slot="end">
          {{ ts.dateIn | timestamp:'HH:mm' }} -&nbsp;
          {{ ts.dateOut | timestamp:'HH:mm' }}
        </span>
      </ion-item>
    }
  } @else {
    @if (fieldS() === 'start') {
      <h4>{{ 'schedule.add.start' | translate }}</h4>
      <ion-item lines="none">
        <ion-input [(ngModel)]="value"
          [maskito]="timeMask"
          [maskitoElement]="maskPredicate"
          aria-label=""></ion-input>
      </ion-item>
    } @else if (fieldS() === 'end') {
      <h4>{{ 'schedule.add.end' | translate }}</h4>
      <ion-item lines="none">
        <ion-input [(ngModel)]="value"
          [maskito]="timeMask"
          [maskitoElement]="maskPredicate"
          aria-label=""></ion-input>
      </ion-item>
    } @else if (fieldS() === 'lunch') {
      <h4>{{ 'report.lunch' | translate }}</h4>
      <ion-item lines="none">
        <ion-input [(ngModel)]="value"
          [maskito]="lunchMask"
          [maskitoElement]="maskPredicate"
          aria-label="">
          <span slot="end">min</span>
        </ion-input>
      </ion-item>
    } @else if (['workHours', 'invoice', 'paycheck'].includes(fieldS())) {
      @if (fieldS() === 'workHours') {
        <h4>{{ 'site.worktime' | translate }}</h4>
      } @else if (fieldS() === 'invoice') {
        <h4>{{ 'report.invoice' | translate }}</h4>
      } @else if (fieldS() === 'paycheck') {
        <h4>{{ 'report.paycheck' | translate }}</h4>
      }
      <ion-button id="adHocDuration"
        color="secondary"
        shape="round">
        @if (valueNumber() > 0) {
          <span>
            {{ valueNumber() | hours }}
          </span>
        } @else {
          {{ 'paycheck.hours' | translate }}
        }
      </ion-button>
      <ion-modal #durationModal
        class="picker-modal"
        trigger="adHocDuration"
        [keepContentsMounted]="true">
        <ng-template>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button (click)="durationModal.dismiss()">
                {{ 'general.cancel' | translate }}
              </ion-button>
            </ion-buttons>
            <ion-buttons>
              <ion-button color="danger"
                (click)="onDuration(0, valueNumber); durationModal.dismiss()">
                {{ 'general.reset' | translate }}
              </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
              <ion-button (click)="onDuration(durationPicker.value, valueNumber); durationModal.dismiss()">
                {{ 'general.select' | translate }}
              </ion-button>
            </ion-buttons>
          </ion-toolbar>
          <ion-picker>
            <ion-picker-column #durationPicker
              [value]="valueNumber()">
              @for (duration of hoursArray; track duration.value) {
                <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
              }
            </ion-picker-column>
          </ion-picker>
        </ng-template>
      </ion-modal>
    } @else if (fieldS() === 'kmPay') {
      <h4>{{ 'timesheet.paymentTypes.kmPay' | translate }}</h4>
      <ion-item lines="none">
        <ion-input [(ngModel)]="valueNumber"
          type="number"
          aria-label="">
          <span slot="end">km</span>
        </ion-input>
      </ion-item>
    } @else if (fieldS() === 'dayAllowance') {
      <h4>{{ 'timesheet.paymentTypes.fullDay' | translate }}</h4>
      <ion-item lines="none">
        <ion-input [(ngModel)]="valueNumber"
          type="number"
          aria-label="">
          <span slot="end">{{ 'timesheet.paymentTypeUnit' | translate }}</span>
        </ion-input>
      </ion-item>
    } @else if (fieldS() === 'change') {
      @if ({
        projects: projects$ | async,
        worksites: worksites$ | async
      }; as vm) {
        <ion-button shape="round"
          color="light"
          (click)="selectProject(vm.projects)">
          @if (selectedProject()?.name) {
            {{ selectedProject().name }}
          } @else {
            {{ 'sites.new.project' | translate }}
          }
        </ion-button>
        <ion-button shape="round"
          color="light"
          (click)="changeSite(vm.worksites)">
          @if (site$ | async; as site) {
            {{ site.name }}
          }
        </ion-button>
      }
    } @else if (fieldS() === 'split') {
      @if ({
        projects: projects$ | async,
        worksites: worksites$ | async
      }; as vm) {
        <div class="flex">
          <div>
            <div class="split-title">{{ 'timesheet.currentTimesheet' | translate }}</div>
            <ion-button color="light"
              disabled="true">
              @if (site$ | async; as site) {
                <ion-label>{{ site.name }}</ion-label>
              }
            </ion-button>
            <div>
              <ion-button color="light"
                disabled="true">
                {{ selectedProject()?.name }}
              </ion-button>
            </div>
            <ion-button id="splitHours1"
              color="secondary"
              shape="round">
              @if (split1Hours(); as duration) {
                <span>
                  {{ duration | hours }}
                </span>
              } @else {
                {{ 'paycheck.hours' | translate }}
              }
            </ion-button>
            <ion-modal #split1Modal
              class="picker-modal"
              trigger="splitHours1"
              [keepContentsMounted]="true">
              <ng-template>
                <ion-toolbar>
                  <ion-buttons slot="start">
                    <ion-button (click)="split1Modal.dismiss()">
                      {{ 'general.cancel' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons>
                    <ion-button color="danger"
                      (click)="onDuration(0, split1Hours); split1Modal.dismiss()">
                      {{ 'general.reset' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons slot="end">
                    <ion-button (click)="onDuration(split1Picker.value, split1Hours); split1Modal.dismiss()">
                      {{ 'general.select' | translate }}
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
                <ion-picker>
                  <ion-picker-column #split1Picker
                    [value]="split1Hours()">
                    @for (duration of hoursArray; track duration.value) {
                      <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
                    }
                  </ion-picker-column>
                </ion-picker>
              </ng-template>
            </ion-modal>
          </div>
          <div>
            <div class="split-title">{{ 'timesheet.newTimesheet' | translate }}</div>
            <ion-button shape="round"
              color="light"
              (click)="changeSite(vm.worksites, true)">
              @if (siteAnother()?.name) {
                {{ siteAnother().name }}
              } @else {
                {{ 'report.selectSite' | translate }}
              }
            </ion-button>
            <div>
              <ion-button shape="round"
                color="light"
                (click)="selectProject(vm.projects, true)">
                @if (projectAnother()?.name) {
                  {{ projectAnother().name }}
                } @else {
                  {{ 'sites.new.project' | translate }}
                }
              </ion-button>
            </div>
            <ion-button id="splitHours2"
              color="secondary"
              shape="round">
              @if (split2Hours(); as duration) {
                <span>
                  {{ duration | hours }}
                </span>
              } @else {
                {{ 'paycheck.hours' | translate }}
              }
            </ion-button>
            <ion-modal #split2Modal
              class="picker-modal"
              trigger="splitHours2"
              [keepContentsMounted]="true">
              <ng-template>
                <ion-toolbar>
                  <ion-buttons slot="start">
                    <ion-button (click)="split2Modal.dismiss()">
                      {{ 'general.cancel' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons>
                    <ion-button color="danger"
                      (click)="onDuration(0, split2Hours); split2Modal.dismiss()">
                      {{ 'general.reset' | translate }}
                    </ion-button>
                  </ion-buttons>
                  <ion-buttons slot="end">
                    <ion-button (click)="onDuration(split2Picker.value, split2Hours); split2Modal.dismiss()">
                      {{ 'general.select' | translate }}
                    </ion-button>
                  </ion-buttons>
                </ion-toolbar>
                <ion-picker>
                  <ion-picker-column #split2Picker
                    [value]="split2Hours()">
                    @for (duration of hoursArray; track duration.value) {
                      <ion-picker-column-option [value]="duration.value">{{ duration.text }}</ion-picker-column-option>
                    }
                  </ion-picker-column>
                </ion-picker>
              </ng-template>
            </ion-modal>
          </div>
        </div>
      }
    } @else if (itemS()?.timesheets.length > 1) {
      <h4>{{ 'nav.userreportadmin' | translate }}</h4>
      <div class="ion-margin-top ion-margin-bottom">
        @for(ts of itemS().timesheets; track ts.guid) {
          <div class="flex-justify-between ion-margin-top">
            {{ ts.siteName }}
            <span>
              {{ ts.dateIn | timestamp:'HH:mm' }} -&nbsp;
              {{ ts.dateOut | timestamp:'HH:mm' }}
            </span>
          </div>
        }
      </div>
    }
    <div class="ion-margin-top">
      <ion-button color="light"
        [disabled]="disabled()"
        (click)="save()">
        {{ 'general.save' | translate }}
      </ion-button>
    </div>
  }
</app-main-dialog>