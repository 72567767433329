import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LoadingComponent } from '@scandium-oy/ngx-scandium';
import { addDays, endOfToday, startOfDay, startOfToday } from 'date-fns';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { EfficiencyItemsComponent } from '../components/efficiency-item/efficiency-item.component';
import { SelectedDayComponent } from '../day/selected-day/selected-day.component';
import { TopCleanersService } from '../services/top-cleaners.service';
import { UsersService } from '../services/users.service';
import { getColor } from '../utility/list';

@Component({
  selector: 'app-efficiency',
  templateUrl: './efficiency.component.html',
  styleUrls: ['./efficiency.component.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    EfficiencyItemsComponent,
    LoadingComponent,
    SelectedDayComponent,
  ],
})
export class EfficiencyComponent {

  selectedDay$ = new BehaviorSubject<Date>(endOfToday());

  private efficiencies$ = combineLatest([this.selectedDay$]).pipe(
    tap(([_]) => setTimeout(() => this.loading = true)),
    debounceTime(500),
    switchMap(([date]) => this.topCleanersService.getByAllByUser(date, false)),
    map((items) => items.map((it) => ({ name: it.name, hours: it.points, color: getColor(it.points), notes: it.notes }))),
    shareReplay(1),
  );

  loading: boolean;

  over$ = this.efficiencies$.pipe(
    map((items) => items.filter((it) => it.color === 'green')),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  almost$ = this.efficiencies$.pipe(
    map((items) => items.filter((it) => it.color === 'yellow')),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  under$ = this.efficiencies$.pipe(
    map((items) => items.filter((it) => it.color === 'red')),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  noData$ = combineLatest([this.efficiencies$, this.usersService.getList(true)]).pipe(
    map(([items, users]) => users.filter((u) => !items.some((it) => it.name === u.displayName))),
    shareReplay(1),
  );

  constructor(
    private topCleanersService: TopCleanersService,
    private usersService: UsersService,
  ) { }

  changeFullDate(date: Date) {
    const today = startOfToday();
    if (date <= today) {
      this.selectedDay$.next(date);
    }
  }

  changeDate(value: number): void {
    const selected = this.selectedDay$.value;
    const newDate = addDays(selected, value);
    const date = startOfDay(newDate);
    this.changeFullDate(date);
  }
}
