import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Dialog } from '@capacitor/dialog';
import { ModalController, NavParams } from '@ionic/angular/standalone';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions, maskitoTransform } from '@maskito/core';
import { TranslateService } from '@ngx-translate/core';
import { fieldSorter, TimestampPipe } from '@scandium-oy/ngx-scandium';
import { parse } from 'date-fns';
import { format } from 'date-fns/format';
import { hoursToMilliseconds } from 'date-fns/hoursToMilliseconds';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { SelectSiteDialog } from 'src/app/components/select-site/select-site.dialog';
import { SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { SubItem } from 'src/app/models/sub-item.model';
import { Timesheet } from 'src/app/models/timesheet.model';
import { HoursPipe } from 'src/app/pipes/duration/hours.pipe';
import { createNewTimesheet, getTimesheetDate } from 'src/app/report/helper';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ClientsService } from 'src/app/services/clients.service';
import { ContractorsService } from 'src/app/services/contractors.service';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { SitesService } from 'src/app/services/sites.service';
import { TimesheetsService } from 'src/app/services/timesheets.service';
import { Roles } from 'src/app/utility/role';
import { hoursArray, reportMillisToMinutesAndSeconds } from 'src/app/utility/time';
import { TsWorkerDate } from '../process-timesheets.component';

@Component({
  selector: 'app-edit-timesheet-dialog',
  templateUrl: './edit-timesheet.dialog.html',
  styleUrls: ['./edit-timesheet.dialog.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainDialogComponent,
    MaskitoDirective,
    FormsModule,
    HoursPipe,
    TimestampPipe,
  ],
})
export class EditTimesheetDialogComponent {
  private clientsService = inject(ClientsService);
  private contractorsService = inject(ContractorsService);
  private _modal = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private navParams = inject(NavParams);
  private sitesService = inject(SitesService);
  private siteProjectsService = inject(SiteProjectsService);
  private timesheetService = inject(TimesheetsService);
  private translate = inject(TranslateService);

  private siteGuid$ = new BehaviorSubject<string>(null);

  fieldS = signal(this.navParams.get<string>('field'));
  itemS = signal(this.navParams.get<TsWorkerDate>('item'));

  selectedTimesheet = signal<Timesheet>(null);
  value = signal<string>('');
  valueNumber = signal(0);
  readonly hoursArray = hoursArray;
  selectedProject = signal<SubItem>(null);
  site$ = this.siteGuid$.asObservable().pipe(
    filter((siteGuid) => siteGuid != null),
    switchMap((siteGuid) => this.sitesService.get(siteGuid)),
    shareReplay(1),
  );

  worksites$ = this.sitesService.getAdminList().pipe(
    map((sites) => sites.filter((s) => !s.archived)),
    switchMap((sites) => combineLatest([this.clientsService.getList(), of(sites)])),
    map(([clients, sites]) => sites.map((site) => {
      site.clientName = clients.find((c) => c.guid === site.client)?.name ?? site.client;
      return site;
    })),
    map((sites) => sites?.sort(fieldSorter(['clientName', 'name']))),
    shareReplay(1),
  );

  projects$ = combineLatest([this.siteGuid$.asObservable(), this.contractorsService.getCurrentContractor()]).pipe(
    filter(([site, contractor]) => site != null && contractor != null),
    switchMap(([site, contractor]) => this.siteProjectsService.getList({ site, contractor: contractor.guid })),
    tap((projects) => {
      if (projects?.length === 1) {
        this.selectedProject.set({ guid: projects[0].guid, name: projects[0].name ?? '' });
      } else {
        const project = projects?.find((it) => it.guid === this.selectedTimesheet().project) ?? null;
        this.selectedProject.set({ guid: project?.guid, name: project?.name ?? '' });
      }
    }),
    shareReplay(1),
  );

  readonly timeMask: MaskitoOptions = {
    mask: [/\d/, /\d/, ':', /\d/, /\d/],
  };

  readonly lunchMask: MaskitoOptions = {
    mask: [/\d/, /\d/],
  };

  readonly workHoursMask: MaskitoOptions = {
    mask: [/\d/, ',', /\d/, /\d/],
  };

  readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();

  split1Hours = signal(0);
  split2Hours = signal(0);
  siteAnother = signal<Site>(null);
  projectAnother = signal<SiteProject>(null);

  disabled = computed(() => {
    if (this.fieldS() === 'change') {
      return this.selectedProject() == null;
    }
    if (this.fieldS() === 'split') {
      return (!this.siteAnother() && !this.projectAnother()) || !this.selectedProject()?.name;
    }
    return false;
  });

  constructor() {
    if (this.itemS().timesheets.length === 1) {
      this.selectTimesheet(this.itemS().timesheets[0]);
    }
    if (this.fieldS() === 'start') {
      this.value.set(format(this.itemS().start, 'HH:mm'));
    }
    if (this.fieldS() === 'end') {
      this.value.set(format(this.itemS().end, 'HH:mm'));
    }
    if (this.fieldS() === 'lunch') {
      const lunch = maskitoTransform(this.itemS().lunch.toString(), this.lunchMask);
      this.value.set(lunch);
    }
    if (this.fieldS() === 'workHours') {
      this.valueNumber.set(reportMillisToMinutesAndSeconds(this.itemS().workHours));
    }
    if (this.fieldS() === 'kmPay') {
      this.valueNumber.set(this.itemS().kmPay);
    }
    if (this.fieldS() === 'dayAllowance') {
      this.valueNumber.set(this.itemS().dayAllowance);
    }
    if (this.fieldS() === 'split') {
      this.split1Hours.set(reportMillisToMinutesAndSeconds(this.itemS().workHours));
    }
    if (this.fieldS() === 'paycheck') {
      this.valueNumber.set(reportMillisToMinutesAndSeconds(this.itemS().paycheck));
    }
    if (this.fieldS() === 'invoice') {
      this.valueNumber.set(reportMillisToMinutesAndSeconds(this.itemS().invoice));
    }
  }

  selectTimesheet(ts: Timesheet) {
    this.selectedTimesheet.set(ts);
    this.siteGuid$.next(ts.site);
  }

  onDuration(value: number | string, updateSignal: WritableSignal<number>) {
    if (value === null) {
      Dialog.prompt({
        title: this.translate.instant('tickets.otherTime'),
        message: this.translate.instant('timesheet.help'),
      }).then((selectedValue) => {
        if (selectedValue) {
          const hours = selectedValue.value.replace(',', '.');
          this.onDuration(+hours, updateSignal);
        }
      });
      return;
    }
    if (value == null) {
      value = 0;
    }
    updateSignal.set(+value);
  }

  selectProject(items: SiteProject[], another = false) {
    const clearButton = this.selectedProject() != null;
    this.modalCtrl.create({ component: SelectDialogComponent<SiteProject>, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (another) {
            if (data.data.clear) {
              this.projectAnother.set(null);
            } else {
              this.projectAnother.set(data.data);
            }
          } else {
            if (data.data.clear) {
              this.selectedProject.set(null);
            } else {
              this.selectedProject.set(data.data);
            }
          }
        }
      });
    });
  }

  changeSite(sites: Site[], another = false) {
    this.modalCtrl.create({ component: SelectSiteDialog, componentProps: { sites } }).then((m) => {
      m.present();
      m.onDidDismiss<Site>().then((data) => {
        if (data.data) {
          if (another) {
            if (data.data.clear) {
              this.siteAnother.set(null);
            } else {
              this.siteAnother.set(data.data);
            }
          } else {
            this.siteGuid$.next(data.data.guid);
            this.selectedProject.set(null);
            this.selectedTimesheet().site = data.data.guid;
            this.selectedTimesheet().siteName = data.data.name;
          }
        }
      });
    });
  }

  save() {
    if (this.fieldS() === 'start') {
      const initial: Date = this.selectedTimesheet().dateIn;
      const dateIn = parse(this.value(), 'HH:mm', initial);
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, dateIn });
    } else if (this.fieldS() === 'end') {
      const initial: Date = this.selectedTimesheet().dateOut;
      const dateOut = parse(this.value(), 'HH:mm', initial);
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, dateOut });
    } else if (this.fieldS() === 'lunch') {
      const lunch = +this.value();
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, lunch });
    } else if (this.fieldS() === 'workHours') {
      const date = getTimesheetDate(this.valueNumber().toString(), this.itemS().lunch);
      const endDate = new Date(this.selectedTimesheet().dateIn);
      endDate.setHours(this.selectedTimesheet().dateIn.getHours() + date.getHours(), date.getMinutes(), 0, 0);
      this.selectedTimesheet().dateOut = endDate;
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, dateOut: this.selectedTimesheet().dateOut });
    } else if (this.fieldS() === 'kmPay') {
      const paymentTypes = this.selectedTimesheet().paymentTypes;
      const kmPay = paymentTypes.find((it) => it.paymentType === 'kmPay');
      kmPay.paymentTypeUnit = this.valueNumber().toString();
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, paymentTypes });
    } else if (this.fieldS() === 'dayAllowance') {
      const paymentTypes = this.selectedTimesheet().paymentTypes;
      const kmPay = paymentTypes.find((it) => it.paymentType === 'fullDay');
      kmPay.paymentTypeUnit = this.valueNumber().toString();
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, paymentTypes });
    } else if (this.fieldS() === 'invoice') {
      const invoiceAmount = hoursToMilliseconds(this.valueNumber());
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, invoiceAmount });
    } else if (this.fieldS() === 'paycheck') {
      const paycheckAmount = hoursToMilliseconds(this.valueNumber());
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, paycheckAmount });
    } else if (this.fieldS() === 'change') {
      console.log(this.selectedTimesheet());
      this._modal.dismiss({
        guid: this.selectedTimesheet().guid, project: this.selectedProject().guid,
        site: this.selectedTimesheet().site, siteName: this.selectedTimesheet().siteName,
      });
    } else if (this.fieldS() === 'split') {
      const date1 = getTimesheetDate(this.split1Hours().toString().replace('.', ','), this.itemS().lunch);
      const date2 = getTimesheetDate(this.split2Hours().toString().replace('.', ','), this.itemS().lunch);
      const user = {
        guid: this.selectedTimesheet().user, displayName: this.selectedTimesheet().username, role: Roles.worker, contractors: [this.contractorsService.contractorS().guid],
      };
      createNewTimesheet(this.timesheetService, this.siteAnother(), user, date2,
        this.selectedTimesheet().date, false, { guid: this.projectAnother().guid, name: this.projectAnother().name });
      const endDate = new Date(this.selectedTimesheet().dateIn);
      endDate.setHours(this.selectedTimesheet().dateIn.getHours() + date1.getHours(), date1.getMinutes(), 0, 0);
      this.selectedTimesheet().dateOut = endDate;
      this._modal.dismiss({ guid: this.selectedTimesheet().guid, dateOut: this.selectedTimesheet().dateOut });
    }
  }

  dismiss() {
    this._modal.dismiss();
  }
}
