import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { ProcessTimesheetsComponent } from './component/process-timesheets.component';

@Component({
  selector: 'app-process-timesheets-page',
  templateUrl: './process-timesheets.page.html',
  styleUrls: ['./process-timesheets.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    MainPageComponent,
    ProcessTimesheetsComponent,
  ],
})
export class ProcessTimesheetsPage {

}
