<app-main-page>
  <div desktopTitle>
    LIVE
  </div>
  <div class="flex">
    <ion-searchbar #filter
      mode="ios"
      [placeholder]="'general.filter' | translate"
      (ionInput)="onFilter(filter)"></ion-searchbar>
    <ion-button (click)="onFilters()">
      <ion-icon slot="icon-only"
        name="filter-outline"></ion-icon>
    </ion-button>
  </div>
  @if (mobileView()) {
    <swiper-container #swiper
      [scrollbar]="true"
      [modules]="swiperModules">
      <swiper-slide>
        @if (prospects$ | async; as items) {
          <h5>
            <ion-button class="empty"
              fill="clear"></ion-button>
            {{ 'live.status.prospect' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            <ion-item class="content new"
              button="true"
              lines="none"
              color="primary"
              (click)="onNewNote('prospect')">
              {{ 'item.add' | translate }}
            </ion-item>
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (didNotAnswer$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.didNotAnswer' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (contacted$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.contacted' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (booked$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.booked' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (meeting$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.meeting' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (offered$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.offered' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content offered"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (contractSend$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.contractSend' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content contract-sent"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (done$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.done' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content done"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (archived$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.archived' | translate }}
            <ion-button color="light"
              fill="clear"
              (click)="slideNext()">
              <ion-icon slot="icon-only"
                name="chevron-forward-outline"></ion-icon>
            </ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content archived"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
      <swiper-slide>
        @if (notInterested$ | async; as items) {
          <h5>
            <ion-button color="light"
              fill="clear"
              (click)="slidePrev()">
              <ion-icon slot="icon-only"
                name="chevron-back-outline"></ion-icon>
            </ion-button>
            {{ 'live.status.notInterested' | translate }}
            <ion-button class="empty"
              fill="clear"></ion-button>
          </h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="swiper-content">
            @for (item of items; track item.guid) {
              <ion-item class="content archived"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </swiper-slide>
    </swiper-container>
  } @else {
    <div class="flex">
      <div class="drop-zone"
        (drop)="drop($event, 'prospect')"
        (dragover)="allowDrop($event)">
        @if (prospects$ | async; as items) {
          <h5>{{ 'live.status.prospect' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            <ion-item class="content new"
              button="true"
              lines="none"
              color="primary"
              (click)="onNewNote('prospect')">
              {{ 'item.add' | translate }}
            </ion-item>
            @if (roleS() === 'super-admin') {
              <ion-item class="content new"
                button="true"
                lines="none"
                color="primary"
                (click)="moveTo(items, 'draft')">
                {{ 'general.move' | translate }}
              </ion-item>
            }
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone"
        (drop)="drop($event, 'didNotAnswer')"
        (dragover)="allowDrop($event)">
        @if (didNotAnswer$ | async; as items) {
          <h5>{{ 'live.status.didNotAnswer' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone"
        (drop)="drop($event, 'contacted')"
        (dragover)="allowDrop($event)">
        @if (contacted$ | async; as items) {
          <h5>{{ 'live.status.contacted' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone"
        (drop)="drop($event, 'booked')"
        (dragover)="allowDrop($event)">
        @if (booked$ | async; as items) {
          <h5>{{ 'live.status.booked' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone"
        (drop)="drop($event, 'meeting')"
        (dragover)="allowDrop($event)">
        @if (meeting$ | async; as items) {
          <h5>{{ 'live.status.meeting' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content"
                button="true"
                lines="none"
                [draggable]="true"
                (dragstart)="drag($event, item)"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone">
        @if (offered$ | async; as items) {
          <h5>{{ 'live.status.offered' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content offered"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone">
        @if (contractSend$ | async; as items) {
          <h5>{{ 'live.status.contractSend' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content contract-sent"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone">
        @if (done$ | async; as items) {
          <h5>{{ 'live.status.done' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content done"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone">
        @if (archived$ | async; as items) {
          <h5>{{ 'live.status.archived' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content archived"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
      <div class="drop-zone">
        @if (notInterested$ | async; as items) {
          <h5>{{ 'live.status.notInterested' | translate }}</h5>
          <div class="ion-margin-bottom">{{ 'live.contacts' | translate:{ num: items.length } }}</div>
          <div class="container">
            @for (item of items; track item.guid) {
              <ion-item class="content archived"
                button="true"
                lines="none"
                (click)="onItem(item)">
                <ng-container [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ item }"></ng-container>
              </ion-item>
            }
          </div>
        }
      </div>
    </div>
  }
</app-main-page>
<ng-template #itemTemplate
  let-item="item">
  <div>
    {{ item.target }}<br />
    <ion-note>
      {{ item.date | timestamp:'d.M.yyyy' }}<br />
    </ion-note>
    <div class="info">
      @if (item.turnoverClass) {
        <br />{{ 'live.turnoverClass' | translate }}: {{ item.turnoverClass }}
      }
      @if (item.personnel >= 0) {
        <br />{{ 'live.personnel' | translate }}: {{ item.personnel }}
      }
    </div>
    @if (item.creator) {
      <ion-chip class="ion-margin-top">{{ item.creator.name }}</ion-chip>
    }
    @if (item.status === 'contacted' && item.contactType) {
      <br /><ion-chip>{{ 'partnerNote.contactTypes.' + item.contactType | translate }}</ion-chip>
    }
    @if (item.status === 'done') {
      <br /><ion-chip>
        <ion-icon name="calendar-outline"></ion-icon>
        @if (item.startDate) {
          {{ item.startDate | timestamp:'d.M.yyyy' }}
        }
      </ion-chip>
      <ion-chip>
        <ion-icon name="library-outline"></ion-icon>
        @if (item.trainingDate) {
          {{ item.trainingDate | timestamp:'d.M.yyyy' }}
        }
      </ion-chip>
    }
  </div>
</ng-template>