import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { SegmentListComponent } from '../components/segment-list/segment-list.component';
import { EfficiencyComponent } from '../efficiency/efficiency.component';
import { ProfitComponent } from '../profit/profit.component';
import { NavigationService } from '../services/navigation.service';
import { UsersService } from '../services/users.service';
import { Roles } from '../utility/role';
import { ReportSiteComponent } from './site/report.component';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports.page.html',
  styleUrls: ['./reports.page.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainPageComponent,
    SegmentListComponent,
    EfficiencyComponent,
    ReportSiteComponent,
    ProfitComponent,
  ],
})
export class ReportsPage {
  private route = inject(ActivatedRoute);
  private navigationService = inject(NavigationService);
  private usersService = inject(UsersService);

  navSegmentValue = signal('efficiency');

  readonly navSegmentButtons = computed(() => {
    const role = this.usersService.role();
    return [
      {
        value: 'efficiency',
        name: 'nav.efficiency',
        show: true,
      },
      {
        value: 'report',
        name: 'nav.sitereportadmin',
        show: true,
      },
      {
        value: 'profit',
        name: 'nav.profit',
        show: [Roles.admin].includes(role),
      },
    ];
  });

  constructor() {
    this.route.queryParams.pipe(
      takeUntilDestroyed(),
      filter((params) => params != null),
    ).subscribe((params) => {
      if (params.tab) {
        this.navSegmentValue.set(params.tab);
      }
    });
  }

  navSegmentChanged(tab: string) {
    if (tab) {
      this.navSegmentValue.set(tab);
      this.navigationService.updateQueryParams({ tab });
    }
  }
}
