import { CommonModule } from '@angular/common';
import { Component, inject, input, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { fieldSorter, TimestampPipe } from '@scandium-oy/ngx-scandium';
import { startOfDay } from 'date-fns';
import { of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { Timesheet } from 'src/app/models/timesheet.model';
import { DurationPipe } from 'src/app/pipes/duration/duration.pipe';
import { ContractorsService } from 'src/app/services/contractors.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { addTimesheet, getDate, updateSum } from 'src/app/utility/timesheet';
import { TsWorker } from './process-timesheets.component';

@Component({
  selector: 'app-check-timesheets',
  templateUrl: './check-timesheets.component.html',
  styleUrls: ['./check-timesheets.component.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    TimestampPipe,
    DurationPipe,
  ],
})
export class CheckTimesheetsComponent {
  private contractorsService = inject(ContractorsService);
  private schedulesService = inject(SchedulesService);

  timesheets = input.required<(Timesheet & { info?: string })[]>();

  workers$ = toObservable(this.timesheets).pipe(
    map((timesheets) => {
      const workers = Array.from<TsWorker>([]);
      timesheets.map((timesheet) => {
        const user = { guid: timesheet.user, name: timesheet.username };
        const old = workers.find((w) => w.guid === user.guid);
        if (old) {
          let oldDate = old.dates.find((it) => it.date.toLocaleDateString('fi') === startOfDay(timesheet.dateIn).toLocaleDateString('fi'));
          if (oldDate) {
            oldDate = addTimesheet(oldDate, timesheet);
            updateSum(timesheet, old, oldDate.lunch);
          } else {
            const date = getDate(this.contractorsService.contractorS(), timesheet);
            const info$ = this.contractorsService.contractorS() == null ? of(timesheet.info) :
              this.schedulesService.getSingle({ site: timesheet.site, date: date.date.toLocaleDateString('fi'), user: user.guid }).pipe(
                filter((schedule) => schedule != null),
                map((schedule) => schedule.information.find((it) => it.user === user.guid)?.text),
                shareReplay(1),
              );
            date.info$ = info$;
            updateSum(timesheet, old, date.lunch);
            old.dates.push(date);
          }
        } else {
          const date = getDate(this.contractorsService.contractorS(), timesheet);
          const info$ = this.contractorsService.contractorS() == null ? of(timesheet.info) :
            this.schedulesService.getSingle({ site: timesheet.site, date: date.date.toLocaleDateString('fi'), user: user.guid }).pipe(
              filter((schedule) => schedule != null),
              map((schedule) => schedule.information.find((it) => it.user === user.guid)?.text),
              shareReplay(1),
            );
          date.info$ = info$;
          const item = {
            guid: user.guid,
            name: user.name,
            dates: [date],
            workHoursSum: signal(0),
            overtime50Sum: signal(0),
            overtime100Sum: signal(0),
            kmSum: signal(0),
            dayAllowanceSum: signal(0),
            paycheckSum: signal(0),
            invoiceSum: signal(0),
          };
          updateSum(timesheet, item, date.lunch);
          workers.push(item);
        }
      });
      return workers.sort(fieldSorter(['name']));
    }),
    shareReplay(1),
  );
}
