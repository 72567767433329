@for (worker of workers$ | async; track worker.guid) {
  <h4>{{ worker.name }}</h4>
  @if (mobileView()) {
    @for (date of worker.dates; track date.date) {
      <div class="card">
        <div class="flex-align-center">
          {{ date.date | timestamp:'d.M.yyyy' }}
          @if (date.absence) {
            <ion-label>
              {{ 'timesheet.absence' | translate }}: {{ 'timesheet.absences.' + date.absence | translate }}
            </ion-label>
          } @else {
            <ion-button (click)="onEdit(date, 'change')">
              <ion-label>{{ date.sites }}</ion-label>
            </ion-button>
          }
        </div>
        <div class="flex-align-center">
          {{ 'report.start' | translate }}:
          @if (date.absence) {
            <ion-button>-</ion-button>
          } @else {
            <ion-button (click)="onEdit(date, 'start')">
              {{ date.start | timestamp:'HH:mm' }}
            </ion-button>
          }
          {{ 'report.end' | translate }}:
          @if (date.absence) {
            <ion-button>-</ion-button>
          } @else {
            <ion-button (click)="onEdit(date, 'end')">
              {{ date.end | timestamp:'HH:mm' }}
            </ion-button>
          }
        </div>
        <div class="flex-align-center">
          {{ 'report.lunch' | translate }}:
          <ion-button (click)="onEdit(date, 'lunch')">
            {{ date.lunch }}min
          </ion-button>
        </div>
        <div class="flex-align-center">
          {{ 'report.workHours' | translate }}:
          <ion-button (click)="onEdit(date, 'workHours')">
            {{ date.workHours | duration }}
          </ion-button>
        </div>
        <div class="flex-align-center">
          {{ 'report.overtime50Hours' | translate }}:
          <ion-button [color]="date.overtime50 <= 0 ? 'primary' : 'danger'">
            @if (date.overtime50 <= 0) {
              -
            } @else {
              {{ date.overtime50 | duration }}
            }
          </ion-button>
          {{ 'report.overtime100Hours' | translate }}:
          <ion-button [color]="date.overtime100 <= 0 ? 'primary' : 'danger'">
            @if (date.overtime100 <= 0) {
              -
            } @else {
              {{ date.overtime100 | duration }}
            }
          </ion-button>
        </div>
        <div class="flex-align-center">
          {{ 'report.km' | translate }}:
          <ion-button (click)="onEdit(date, 'kmPay')">
            @if (date.kmPay <= 0) {
              -
            } @else {
              {{ date.kmPay }}
            }
          </ion-button>
          {{ 'report.dayAllowance' | translate }}:
          <ion-button (click)="onEdit(date, 'dayAllowance')">
            @if (date.dayAllowance <= 0) {
              -
            } @else {
              {{ date.dayAllowance }}
            }
          </ion-button>
        </div>
        <div class="flex-align-center">
          {{ 'report.paycheck' | translate }}:
          <ion-button (click)="onEdit(date, 'paycheck')">
            {{ date.paycheck | duration }}
          </ion-button>
          {{ 'report.invoice' | translate }}:
          @if (date.absence) {
            <ion-button>-</ion-button>
          } @else {
            <ion-button (click)="onEdit(date, 'invoice')">
              {{ date.invoice | duration }}
            </ion-button>
          }
        </div>
      </div>
    }
  } @else {
    <div>
      <ion-item lines="none"
        color="primary"
        class="header">
        <div class="grid">
          <div>{{ 'report.date' | translate }}</div>
          <div>{{ 'report.site' | translate }}</div>
          <div>{{ 'report.start' | translate }}</div>
          <div>{{ 'report.end' | translate }}</div>
          <div>{{ 'report.lunch' | translate }}</div>
          <div>{{ 'report.workHours' | translate }}</div>
          <div>{{ 'report.overtime50Hours' | translate }}</div>
          <div>{{ 'report.overtime100Hours' | translate }}</div>
          <div>{{ 'report.km' | translate }}</div>
          <div>{{ 'report.dayAllowance' | translate }}</div>
          <div>{{ 'report.paycheck' | translate }}</div>
          <div>{{ 'report.invoice' | translate }}</div>
        </div>
      </ion-item>
      @for (date of worker.dates; track date.date) {
        <ion-item color="secondary"
          lines="none">
          <div class="grid">
            <div>{{ date.date | timestamp:'d.M.yyyy' }}</div>
            <div>
              @if (date.absence) {
                <ion-label>
                  {{ 'timesheet.absence' | translate }}: {{ 'timesheet.absences.' + date.absence | translate }}
                </ion-label>
              } @else {
                <ion-button (click)="onEdit(date, 'change')">
                  <ion-label>{{ date.sites }}</ion-label>
                </ion-button>
              }
            </div>
            <div>
              @if (date.absence) {
                <ion-button>-</ion-button>
              } @else {
                <ion-button (click)="onEdit(date, 'start')">
                  {{ date.start | timestamp:'HH:mm' }}
                </ion-button>
              }
            </div>
            <div>
              @if (date.absence) {
                <ion-button>-</ion-button>
              } @else {
                <ion-button (click)="onEdit(date, 'end')">
                  {{ date.end | timestamp:'HH:mm' }}
                </ion-button>
              }
            </div>
            <div>
              <ion-button (click)="onEdit(date, 'lunch')">
                {{ date.lunch }}min
              </ion-button>
            </div>
            <div>
              <ion-button (click)="onEdit(date, 'workHours')">
                {{ date.workHours | duration }}
              </ion-button>
            </div>
            <div>
              <ion-button [color]="date.overtime50 <= 0 ? 'primary' : 'danger'">
                @if (date.overtime50 <= 0) {
                  -
                } @else {
                  {{ date.overtime50 | duration }}
                }
              </ion-button>
            </div>
            <div>
              <ion-button [color]="date.overtime100 <= 0 ? 'primary' : 'danger'">
                @if (date.overtime100 <= 0) {
                  -
                } @else {
                  {{ date.overtime100 | duration }}
                }
              </ion-button>
            </div>
            <div>
              <ion-button (click)="onEdit(date, 'kmPay')">
                @if (date.kmPay <= 0) {
                  -
                } @else {
                  {{ date.kmPay }}
                }
              </ion-button>
            </div>
            <div>
              <ion-button (click)="onEdit(date, 'dayAllowance')">
                @if (date.dayAllowance <= 0) {
                  -
                } @else {
                  {{ date.dayAllowance }}
                }
              </ion-button>
            </div>
            <div>
              <ion-button (click)="onEdit(date, 'paycheck')">
                {{ date.paycheck | duration }}
              </ion-button>
            </div>
            <div>
              @if (date.absence) {
                <ion-button>-</ion-button>
              } @else {
                <ion-button (click)="onEdit(date, 'invoice')">
                  {{ date.invoice | duration }}
                </ion-button>
              }
            </div>
            @if (!date.absence) {
              @if (date.requested) {
                <div class="flex flex-center">
                  <ion-icon name="alarm-outline"></ion-icon>
                </div>
              } @else {
                <ion-button (click)="onEdit(date, 'split')">
                  <ion-icon slot="icon-only"
                    name="git-merge-outline"></ion-icon>
                </ion-button>
              }
            }
          </div>
        </ion-item>
      }
      <ion-item lines="none"
        color="primary"
        class="footer">
        <div class="grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>{{ worker.workHoursSum() | duration }}</div>
          <div>{{ worker.overtime50Sum() | duration }}</div>
          <div>{{ worker.overtime100Sum() | duration }}</div>
          <div>{{ worker.kmSum() }}</div>
          <div>{{ worker.dayAllowanceSum() }}</div>
          <div>{{ worker.paycheckSum() | duration }}</div>
          <div>{{ worker.invoiceSum() | duration }}</div>
        </div>
      </ion-item>
    </div>
  }
} @empty {
  <p>
    <app-no-results></app-no-results>
  </p>
}
@if (timesheets().length > 0) {
  <div class="ion-margin-top">
    <ion-button color="light"
      (click)="askCheck()">
      {{ 'timesheet.askCheck' | translate }}
    </ion-button>
    <ion-button color="light"
      (click)="check()">
      {{ 'timesheet.acceptAndLock' | translate }}
    </ion-button>
    <ion-button color="light"
      [disabled]="true">
      {{ 'timesheet.toReport' | translate }}
    </ion-button>
  </div>
}