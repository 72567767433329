<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
  </div>
  <app-check-timesheets [timesheets]="timesheetsS()"></app-check-timesheets>
  @if (timesheetsS().length === 0) {
    <p>
      <app-no-results></app-no-results>
    </p>
  }
  <div class="ion-margin">
    <ion-button color="light"
      [disabled]="timesheetsS().length === 0"
      (click)="check()">
      {{ 'timesheet.sendCheck' | translate }}
    </ion-button>
  </div>
</app-main-dialog>