@if (selectedDay$ | async; as date) {
  <div class="date">
    <a (click)="changeDate(-1)">
      <ion-icon name="chevron-back-outline"></ion-icon>
    </a>
    <div class="date-container">
      {{ 'weekdays.' + date.getDay() | translate }}
      <app-selected-day id="schedule"
        [currentDay]="date | date:'dd.MM.yyyy'"
        (changed)="changeFullDate($event)"></app-selected-day>
    </div>
    <a (click)="changeDate(1)">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </a>
  </div>
}
@if (loading) {
  <app-loading></app-loading>
} @else {
  <h4>{{ 'efficiency.over' | translate }}</h4>
  <p>{{ 'efficiency.overNote' | translate }}</p>
  @if (over$ | async; as users) {
    <app-efficiency-items [items]="users"></app-efficiency-items>
  } @else {
    <app-loading></app-loading>
  }
  <h4>{{ 'efficiency.almost' | translate }}</h4>
  <p>{{ 'efficiency.almostNote' | translate }}</p>
  @if (almost$ | async; as users) {
    <app-efficiency-items [items]="users"></app-efficiency-items>
  } @else {
    <app-loading></app-loading>
  }
  <h4>{{ 'efficiency.under' | translate }}</h4>
  <p>{{ 'efficiency.underNote' | translate }}</p>
  @if (under$ | async; as users) {
    <app-efficiency-items [items]="users"></app-efficiency-items>
  } @else {
    <app-loading></app-loading>
  }
  <h4>{{ 'efficiency.noData' | translate }}</h4>
  <p>{{ 'efficiency.noDataNote' | translate }}</p>
  @if (noData$ | async; as users) {
    <div class="flex">
      @for (user of users; track user) {
        <div class="user">
          {{ user.displayName }}
        </div>
      }
    </div>
  } @else {
    <app-loading></app-loading>
  }
}