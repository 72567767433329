import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fieldSorter, LoadingComponent } from '@scandium-oy/ngx-scandium';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CalendarComponent } from '../calendar/component/calendar.component';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { SegmentListComponent } from '../components/segment-list/segment-list.component';
import { SnapLinksComponent } from '../components/snap-links/snap-links.component';
import { WeekSwitchComponent } from '../components/week-switch/week-switch.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ContractorsService } from '../services/contractors.service';
import { SiteProjectsService } from '../services/site-projects.service';
import { SitesService } from '../services/sites.service';
import { UsersService } from '../services/users.service';
import { SitesMapComponent } from '../sites/map/sites.map.component';
import { defaultProjectName } from '../utility/kanban';
import { Roles } from '../utility/role';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects.page.html',
  styleUrls: ['./projects.page.scss'],
  imports: [
    CommonModule,
    AppCommonModule,
    MainPageComponent,
    SegmentListComponent,
    WeekSwitchComponent,
    CalendarComponent,
    LoadingComponent,
    DashboardComponent,
    SitesMapComponent,
    SnapLinksComponent,
  ],
})
export class ProjectsPage {
  private contractorsService = inject(ContractorsService);
  private siteProjectsService = inject(SiteProjectsService);
  private sitesService = inject(SitesService);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  private filter$ = new BehaviorSubject('');
  private sites$ = this.sitesService.getActiveList().pipe(
    filter((sites) => sites.length > 0),
  );

  private projects$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    switchMap((contractor) => this.siteProjectsService.getList({ contractor: contractor.guid })),
  );

  navSegmentValue = signal('overview');

  readonly navSegmentButtons = [
    {
      value: 'overview',
      name: 'nav.overview',
      show: true,
    },
    {
      value: 'map',
      name: 'sites.showOnMap',
      show: true,
    },
    {
      value: 'calendar',
      name: 'nav.calendar',
      show: true,
    },
    {
      value: 'readiness',
      name: 'nav.dashboard',
      show: true,
    },
    {
      value: 'invoice',
      name: 'nav.invoicing',
      show: true,
    },
  ];

  selectedDay = signal<Date>(null);
  loading = signal(true);

  sitesWithProjects$ = combineLatest([this.contractorsService.getCurrentContractor(), this.usersService.getCurrentUser(), this.usersService.role$]).pipe(
    filter(([contractor, _user, role]) => contractor != null || [Roles.manager].includes(role)),
    switchMap(([contractor, user, role]) => combineLatest([
      this.sites$,
      this.siteProjectsService.getList(
        role === Roles.manager ? { site: user.worksites[0] } : { contractor: contractor.guid },
      ),
      this.filter$.asObservable(),
    ])),
    map(([sites, projects, filterValue]) => sites
      .filter((site) => site.project.toLowerCase().includes(filterValue.toLowerCase()))
      .filter((site) => projects.some((it) => it.site === site.guid && it.periods?.length > 0))
      .filter((site) => !site.archived),
    ),
    map((items) => items.sort(fieldSorter(['project']))),
    tap(() => this.loading.set(false)),
    shareReplay(1),
  );

  snapLinks$ = combineLatest([this.projects$]).pipe(
    map(([projects]) => {
      return [
        {
          title: projects.filter((it) => it.name === defaultProjectName).length.toString(),
          name: this.translate.instant('profit.maintance'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          action: () => { },
        },
        {
          title: projects.filter((it) => it.name !== defaultProjectName).length.toString(),
          name: this.translate.instant('profit.cleanings'),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          action: () => { },
        },
      ];
    }),
    shareReplay(1),
  );

  navSegmentChanged(value: string) {
    if (value) {
      this.navSegmentValue.set(value);
    }
  }

  onDateChange(date: Date) {
    this.selectedDay.set(date);
  }

  onFilter(event: CustomEvent) {
    this.filter$.next(event.detail.value);
  }
}
